import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'

import {SharedImgComponent} from './shared-img.component'

@NgModule({
  declarations: [
    SharedImgComponent,
  ],
  exports: [
    SharedImgComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class SharedImgModule {
}
